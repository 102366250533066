import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  Container,
  Paper,
  TextField,
  Button,
  Typography,
  Box,
  CircularProgress,
  MenuItem,
  Snackbar,
  Alert as MuiAlert
} from '@mui/material';
import { AuthService } from '../../services/auth.service';
import { SignupData } from '../../types/auth.types';

const validationSchema = Yup.object().shape({
  companyName: Yup.string(),
  firstName: Yup.string(),
  lastName: Yup.string(),
  email: Yup.string().email('Invalid email'),
  phoneNumber: Yup.string(),
  accountNumber: Yup.string(),
  ifscCode: Yup.string(),
  bankAccountName: Yup.string(),
  xFlowAccountId: Yup.string(),
  accountType: Yup.string().oneOf(['treasury', 'crossBorder', 'both']),
  clientCode: Yup.string(),
  xflowBankAccountDetails: Yup.object().shape({
    type: Yup.string(),
    address: Yup.string(),
    beneficiaryName: Yup.string(),
    accountNumber: Yup.string(),
    metadata: Yup.object().shape({
      domesticCredit: Yup.string(),
      domesticDebit: Yup.string(),
      domesticFastCredit: Yup.string(),
      domesticWire: Yup.string(),
      globalWire: Yup.string(),
      iban: Yup.string(),
      last4: Yup.string(),
      number: Yup.string()
    }),
    xflowAddressId: Yup.string()
  })
});

const initialValues: SignupData = {
  // Customer Account Details
  email: "",
  firstName: "",
  lastName: "",
  companyName: "",
  phoneNumber: "",
  clientCode: "",
  xFlowAccountId: "",

  // Customer Bank Account Details
  accountNumber: "",
  ifscCode: "",
  bankAccountName: "",

  // Account Type (Customer's Interest)
  accountType: "treasury",

  // Customer XFlow Bank Account Details
  xflowBankAccountDetails: {
    type: "",
    address: "",
    beneficiaryName: "",
    accountNumber: "",
    xflowAddressId: "",
    
    // Customer XFlow Bank Account Metadata
    metadata: {
      domesticCredit: "",
      domesticDebit: "",
      domesticFastCredit: "",
      domesticWire: "",
      globalWire: "",
      iban: "",
      last4: "",
      number: ""
    }
  }
};

const Signup: React.FC = () => {
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
    setError('');
  };

  const handleCloseSuccessSnackbar = () => {
    setOpenSuccessSnackbar(false);
    setSuccess('');
  };

  const handleSubmit = async (values: SignupData, { setSubmitting }: any) => {
    try {
      setError('');
      const response = await AuthService.signup(values);
      setSuccess(`Registration successful! Account ID: ${response.accountId}, Bank Account ID: ${response.bankAccountId}`);
      setOpenSuccessSnackbar(true);
    } catch (err: any) {
      setError(err.response?.data?.responseMessage || 'An error occurred during registration');
      setOpenSnackbar(true);
    } finally {
      setSubmitting(false);
    }
  };

  const handleEnterKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // Check if the pressed key is Enter
    if (event.key === 'Enter') {
      // Prevent default behavior of Enter key
      event.preventDefault();
      
      // Get all input fields
      const inputs = document.querySelectorAll('input, select, textarea');
      
      // Convert to array and find current input's index
      const currentIndex = Array.from(inputs).indexOf(event.target as HTMLElement);
      
      // Move to next input if it exists
      if (currentIndex < inputs.length - 1) {
        (inputs[currentIndex + 1] as HTMLElement).focus();
      }
    }
  };

  return (
    <Container maxWidth="sm">
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity="error"
          onClose={handleCloseSnackbar}
        >
          {error}
        </MuiAlert>
      </Snackbar>

      <Snackbar
        open={openSuccessSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSuccessSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity="success"
          onClose={handleCloseSuccessSnackbar}
        >
          {success}
        </MuiAlert>
      </Snackbar>

      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Paper
          elevation={3}
          sx={{
            padding: 4,
            width: '100%',
          }}
        >
          <Typography variant="h4" align="center" gutterBottom>
            Onboard our customer 
          </Typography>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, handleChange, handleBlur, values, isSubmitting }) => (
              <Form>
                {/* Customer Account Details Section */}
                <Box sx={{ mb: 4 }}>
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    Customer Account Details
                  </Typography>
                  <Box sx={{ pl: 2 }}>
                    <TextField
                      fullWidth
                      name="email"
                      label="Email"
                      type="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      margin="normal"
                      onKeyDown={handleEnterKey}
                    />
                    <TextField
                      fullWidth
                      name="firstName"
                      label="First Name"
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      margin="normal"
                      onKeyDown={handleEnterKey}
                    />
                    <TextField
                      fullWidth
                      name="lastName"
                      label="Last Name"
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      margin="normal"
                      onKeyDown={handleEnterKey}
                    />
                    <TextField
                      fullWidth
                      name="companyName"
                      label="Company Name"
                      value={values.companyName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      margin="normal"
                      onKeyDown={handleEnterKey}
                    />
                    <TextField
                      fullWidth
                      name="phoneNumber"
                      label="Phone Number with Country Code (e.g. +919876543210)"
                      value={values.phoneNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      margin="normal"
                      onKeyDown={handleEnterKey}
                    />
                    <TextField
                      fullWidth
                      name="clientCode"
                      label="Client Code"
                      value={values.clientCode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      margin="normal"
                      onKeyDown={handleEnterKey}
                    />
                    <TextField
                      fullWidth
                      name="xFlowAccountId"
                      label="XFlow Account ID"
                      value={values.xFlowAccountId}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      margin="normal"
                      onKeyDown={handleEnterKey}
                    />
                  </Box>
                </Box>

                {/* Customer Bank Account Details Section */}
                <Box sx={{ mb: 4 }}>
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    Customer Indian Bank Account Details
                  </Typography>
                  <Box sx={{ pl: 2 }}>
                    <TextField
                      fullWidth
                      name="accountNumber"
                      label="Bank Account Number"
                      value={values.accountNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      margin="normal"
                      onKeyDown={handleEnterKey}
                    />
                    <TextField
                      fullWidth
                      name="ifscCode"
                      label="IFSC Code"
                      value={values.ifscCode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      margin="normal"
                      onKeyDown={handleEnterKey}
                    />
                    <TextField
                      fullWidth
                      name="bankAccountName"
                      label="Bank Account Holder Name"
                      value={values.bankAccountName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      margin="normal"
                      onKeyDown={handleEnterKey}
                    />
                  </Box>
                </Box>

                {/* Customer XFlow Bank Account Details Section */}
                <Box sx={{ mb: 4 }}>
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    Customer XFlow Bank Account Details
                  </Typography>
                  <Box sx={{ pl: 2 }}>
                    {/* <TextField
                      fullWidth
                      name="xflowBankAccountDetails.type"
                      label="Account Type"
                      value={values.xflowBankAccountDetails.type}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      margin="normal"
                      onKeyDown={handleEnterKey}
                    /> */}
                    <TextField
                      fullWidth
                      name="xflowBankAccountDetails.address"
                      label="Address"
                      value={values.xflowBankAccountDetails.address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      margin="normal"
                      onKeyDown={handleEnterKey}
                    />
                    {/* <TextField
                      fullWidth
                      name="xflowBankAccountDetails.beneficiaryName"
                      label="Beneficiary Name"
                      value={values.xflowBankAccountDetails.beneficiaryName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      margin="normal"
                      onKeyDown={handleEnterKey}
                    /> */}
                    {/* <TextField
                      fullWidth
                      name="xflowBankAccountDetails.accountNumber"
                      label="XFlow Bank Account Number"
                      value={values.xflowBankAccountDetails.accountNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      margin="normal"
                      onKeyDown={handleEnterKey}
                    />
                    <TextField
                      fullWidth
                      name="xflowBankAccountDetails.xflowAddressId"
                      label="XFlow Address ID"
                      value={values.xflowBankAccountDetails.xflowAddressId}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      margin="normal"
                      onKeyDown={handleEnterKey}
                    /> */}
                  </Box>
                </Box>

                {/* Customer XFlow Bank Account Metadata Section
                <Box sx={{ mb: 4 }}>
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    Customer XFlow Bank Account Metadata
                  </Typography>
                  <Box sx={{ pl: 2 }}>
                    <TextField
                      fullWidth
                      name="xflowBankAccountDetails.metadata.domesticCredit"
                      label="Domestic Credit"
                      value={values.xflowBankAccountDetails.metadata.domesticCredit}
                      onChange={handleChange}
                      margin="normal"
                      onKeyDown={handleEnterKey}
                    />
                    <TextField
                      fullWidth
                      name="xflowBankAccountDetails.metadata.domesticDebit"
                      label="Domestic Debit"
                      value={values.xflowBankAccountDetails.metadata.domesticDebit}
                      onChange={handleChange}
                      margin="normal"
                      onKeyDown={handleEnterKey}
                    />
                    <TextField
                      fullWidth
                      name="xflowBankAccountDetails.metadata.domesticFastCredit"
                      label="Domestic Fast Credit"
                      value={values.xflowBankAccountDetails.metadata.domesticFastCredit}
                      onChange={handleChange}
                      margin="normal"
                      onKeyDown={handleEnterKey}
                    />
                    <TextField
                      fullWidth
                      name="xflowBankAccountDetails.metadata.domesticWire"
                      label="Domestic Wire"
                      value={values.xflowBankAccountDetails.metadata.domesticWire}
                      onChange={handleChange}
                      margin="normal"
                      onKeyDown={handleEnterKey}
                    />
                    <TextField
                      fullWidth
                      name="xflowBankAccountDetails.metadata.globalWire"
                      label="Global Wire"
                      value={values.xflowBankAccountDetails.metadata.globalWire}
                      onChange={handleChange}
                      margin="normal"
                      onKeyDown={handleEnterKey}
                    />
                    <TextField
                      fullWidth
                      name="xflowBankAccountDetails.metadata.iban"
                      label="IBAN"
                      value={values.xflowBankAccountDetails.metadata.iban}
                      onChange={handleChange}
                      margin="normal"
                      onKeyDown={handleEnterKey}
                    />
                    <TextField
                      fullWidth
                      name="xflowBankAccountDetails.metadata.last4"
                      label="Last 4 Digits"
                      value={values.xflowBankAccountDetails.metadata.last4}
                      onChange={handleChange}
                      margin="normal"
                      onKeyDown={handleEnterKey}
                    />
                    <TextField
                      fullWidth
                      name="xflowBankAccountDetails.metadata.number"
                      label="Account Number"
                      value={values.xflowBankAccountDetails.metadata.number}
                      onChange={handleChange}
                      margin="normal"
                      onKeyDown={handleEnterKey}
                    />
                  </Box>
                </Box> */}

                {/* Customer's Interest Section
                <Box sx={{ mb: 4 }}>
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    Customer's Interest
                  </Typography>
                  <Box sx={{ pl: 2 }}>
                    <TextField
                      fullWidth
                      select
                      name="accountType"
                      label="Account Type"
                      value={values.accountType}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      margin="normal"
                    >
                      <MenuItem value="treasury">Treasury</MenuItem>
                      <MenuItem value="crossBorder">Cross Border</MenuItem>
                      <MenuItem value="both">Both</MenuItem>
                    </TextField>
                  </Box>
                </Box> */}

                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  size="large"
                  disabled={isSubmitting}
                  sx={{ mt: 3 }}
                >
                  {isSubmitting ? <CircularProgress size={24} /> : 'Sign Up'}
                </Button>
              </Form>
            )}
          </Formik>
        </Paper>
      </Box>
    </Container>
  );
};

export default Signup; 

